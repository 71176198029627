import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { ProductReturnsState } from '../models/slices/ProductReturnsState';
import { ProductReturn } from '../models/productReturns/ProductReturn';
import { LoadProductReturnsResponse } from '../models/productReturns/LoadProductReturnsResponse';
import { Listing } from '../models/Listing';
import { PartnerSummary } from '../models/partners/PartnerSummary';
import { AgentSummary } from '../models/common/AgentSummary';

const initialState: ProductReturnsState = {
  productReturns: undefined,
  partners: undefined,
  agents: undefined,
  selectedReturn: undefined,
};

const productReturnsSlice = createSlice({
  name: 'productReturns',
  initialState,
  reducers: {
    setResponse: (state, action: PayloadAction<LoadProductReturnsResponse | undefined>) => {
      state.productReturns = action.payload?.productReturns;
    },
    setProductReturns: (state, action: PayloadAction<Listing<ProductReturn> | undefined | null>) => {
      state.productReturns = action.payload;
    },
    setPartners: (state, action: PayloadAction<PartnerSummary[] | undefined>) => {
      state.partners = action.payload;
    },
    setAgents: (state, action: PayloadAction<AgentSummary[] | undefined>) => {
      state.agents = action.payload;
    },
    setSelectedReturn: (state, action: PayloadAction<ProductReturn | undefined>) => {
      state.selectedReturn = action.payload;
    },
  },
});

export const { setResponse, setProductReturns, setPartners, setAgents, setSelectedReturn } =
  productReturnsSlice.actions;

export const selectProductReturns = (state: RootState): Listing<ProductReturn> | null | undefined =>
  state.localData.productReturns.productReturns;
export const selectPartners = (state: RootState): PartnerSummary[] | undefined =>
  state.localData.productReturns.partners;
export const selectAgents = (state: RootState): AgentSummary[] | undefined => state.localData.productReturns.agents;
export const selectSelectedReturn = (state: RootState): ProductReturn | undefined =>
  state.localData.productReturns.selectedReturn;

export default productReturnsSlice.reducer;
