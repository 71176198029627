import React from 'react';
import { Box, Button, LinearProgress, SwipeableDrawer, Typography } from '@mui/material';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ProductStatusValues } from './ProductReturns';
import { ReturnStatus } from '../../../models/productReturns/ReturnStatus';
import { FormTextField } from '../../common/input/FormTextField';

import { FormRadioGroup } from '../../common/input/FormRadioGroup';
import { getStatus } from './Status';

interface Props {
  data: ProductStatusValues;
  onClose: () => void;
  onSave: (values: ProductStatusValues) => void;
}

export const EditReturnStatus: React.FC<Props> = ({ data, onClose, onSave }: Props) => {
  const initialValues: ProductStatusValues = {
    ...data,
  };

  const handleSubmit = (value: ProductStatusValues): void => onSave(value);
  const validationSchema = Yup.object({
    status: Yup.number().min(1, 'Kérem adja meg az eredményt').required('Kérem adja meg az eredményt'),
    rejectionReason: Yup.string().when('status', (values, schema) => {
      const [status] = values;
      if (status === ReturnStatus.rejected) {
        return schema.required('Kérem adja meg a visszautasítás okát');
      }
      return schema.notRequired();
    }),
  });

  const statusOptions = [
    {
      value: getStatus(ReturnStatus.closed)?.label,
      key: ReturnStatus.closed,
    },
    {
      value: getStatus(ReturnStatus.rejected)?.label,
      key: ReturnStatus.rejected,
    },
  ];

  return (
    <SwipeableDrawer anchor="right" open onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 400 }, p: 3 }}>
        <Typography variant="h5">Lezárás</Typography>
        <Box sx={{ pt: 4 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, errors, values, touched, setFieldValue }) => {
              const status = typeof values.status === 'string' ? parseInt(values.status) : values.status;
              return (
                <>
                  {isSubmitting && (
                    <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                      <LinearProgress />
                    </Box>
                  )}
                  <Form>
                    <FormRadioGroup label="Eredmény" name="status" id="status" options={statusOptions} />
                    {status === ReturnStatus.rejected && (
                      <Box pb={2}>
                        <FormTextField
                          size="small"
                          label="Visszautasítás oka"
                          name="rejectionReason"
                          id="rejectionReason"
                          fullWidth
                          multiline
                        />
                      </Box>
                    )}

                    <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button onClick={onClose} disabled={isSubmitting} sx={{ mr: 2 }}>
                        Mégse
                      </Button>
                      <Button variant="contained" type="submit" disabled={isSubmitting || data?.id === -1}>
                        Mentés
                      </Button>
                    </Box>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
