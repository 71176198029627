import { useMemo } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  Chart as ChartJS,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useFormat } from '../../../utility/useFormat';
import { TransactionTrend as TransactionTrendModel, Trend } from '../../../models/common/TransactionTrend';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface Props {
  data: TransactionTrendModel;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Filler, Legend);

export const TransactionTrend: React.FC<Props> = ({ data }: Props) => {
  const { formatNumber } = useFormat();

  const fulfillmentOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      datalabels: {
        labels: {
          value: {
            align: 'top' as any,
            anchor: 'end' as any,
            offset: 0,
            color: '#000',
            formatter: function (value: any, context: any) {
              if (context.dataset.type === 'bar') {
                return formatNumber(value);
              }

              return null;
            },
          },
        },
      },
    },
  };

  const monthlyData = useMemo(() => {
    const labels: string[] = [];
    const transactions: number[] = [];
    const income: number[] = [];
    const points: number[] = [];

    data.monthlyTrend.forEach((trend: Trend) => {
      labels.push(trend.label);
      transactions.push(trend.numberOfTransactions);
      income.push(trend.income);
      points.push(trend.points);
    });

    const trend = {
      labels,
      datasets: [
        {
          type: 'line' as const,
          label: 'Nettó árbevétel (Figyelj! 100eFt-ban megadott érték, tehát: 100eFt)',
          data: income,
          borderColor: 'rgb(21, 42, 230)',
          backgroundColor: 'rgba(21, 42, 230)',
        },
        {
          type: 'line' as const,
          label: 'Pontok(ezer)',
          data: points,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgb(237, 125, 49)',
        },
        {
          type: 'bar' as const,
          label: 'Tranzakciók (darab)',
          data: transactions,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171)',
        },
      ],
    };
    return trend;
  }, [data]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Havi trend
        </Typography>
        <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
          <Chart
            options={{
              ...fulfillmentOptions,
              plugins: {
                ...fulfillmentOptions.plugins,
                title: {
                  display: true,
                  text: 'Havi trend',
                },
              },
            }}
            type="bar"
            data={monthlyData}
            plugins={[ChartDataLabels]}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
