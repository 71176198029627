import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from '../slices/userSlice';
import applicationReducer from '../slices/applicationSlice';
import partnersReducer from '../slices/partnersSlice';
import myTargetReducer from '../slices/myTargetSlice';
import adminReducer from '../slices/adminSlice';
import productReturnsReducer from '../slices/productReturnsSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['user'],
};

const localStoredReducer = combineReducers({
  user: userReducer,
  application: applicationReducer,
  partners: partnersReducer,
  myTarget: myTargetReducer,
  admin: adminReducer,
  productReturns: productReturnsReducer,
});

const persistedReducer = persistReducer(persistConfig, localStoredReducer);

const rootReducer = combineReducers({
  localData: persistedReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
