import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Access } from '../../../access/Access';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage } from '../../../slices/applicationSlice';
import {
  selectAgents,
  selectPartners,
  selectProductReturns,
  selectSelectedReturn,
  setAgents,
  setPartners,
  setProductReturns,
  setSelectedReturn as setSelectedReturnAction,
} from '../../../slices/productReturnsSlice';
import { Loading } from '../../common/Loading';

import { ProductReturn } from '../../../models/productReturns/ProductReturn';
import { LoadProductReturnsRequest } from '../../../models/productReturns/LoadProductReturnsRequest';
import { LoadProductReturnsResponse } from '../../../models/productReturns/LoadProductReturnsResponse';
import { LoadReturnCriteria } from '../../../models/productReturns/LoadReturnCriteria';
import { StatusFilter } from './StatusFilter';
import { ReasonFilter } from './ReasonFilter';
import { TableHeaderCell, TableRowData } from '../../common/Table';
import { ReturnStatus } from '../../../models/productReturns/ReturnStatus';
import { Status } from './Status';
import { Reason } from './Reason';
import { ReadMore } from '../../common/ReadMore';
import { ReturnReason } from '../../../models/productReturns/ReturnReason';
import { useFormat } from '../../../utility/useFormat';
import { Listing } from '../../../models/Listing';
import { TableRemote } from '../../common/TableRemote';
import AddIcon from '@mui/icons-material/Add';
import { EditReturn } from './EditReturn';
import { PartnerSummary } from '../../../models/partners/PartnerSummary';
import { AgentSummary } from '../../../models/common/AgentSummary';
import { isAdminRole, isStorekeeperRole } from '../../../slices/userSlice';
import { Product } from '../../../models/common/Product';
import { SaveProductReturnRequest } from '../../../models/productReturns/SaveProductReturnRequest';
import { SaveProductReturnResponse } from '../../../models/productReturns/SaveProductReturnResponse';
import { StoreProductReturn } from '../../../models/productReturns/StoreProductReturn';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StatusButton } from './TaskButton';
import { ChangeStatusCriteria } from '../../../models/productReturns/ChangeStatusCriteria';
import { EditReturnStatus } from './EditReturnStatus';
import { Info } from '../../common/Info';
import { Gallery } from '../../common/Gallery';

const defaultCriteria: LoadReturnCriteria = {
  page: 0,
  pageSize: 25,
  sortBy: 'date',
  sortDirection: 'asc',
  searchTerm: '',
  statuses: [ReturnStatus.new, ReturnStatus.inProgress, ReturnStatus.closed, ReturnStatus.rejected],
  reasons: [
    ReturnReason.warranty,
    ReturnReason.damagedOnDelivery,
    ReturnReason.misorder,
    ReturnReason.broken,
    ReturnReason.other,
  ],
};

export interface ProductReturnValues {
  id: number;
  status: number;
  serialNumber: string;
  date: string;
  agentId: number | undefined;
  partnerId: number | undefined;
  description: string;
  reason: number;
  deliveryDate: Date | undefined | null;
  photos: string[];
  product: Product | undefined;
  productCount?: number;
}

export interface ProductStatusValues {
  id: number;
  status: number;
  rejectionReason?: string;
}

export const ProductReturns: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const productReturns: Listing<ProductReturn> | null | undefined = useSelector(selectProductReturns);
  const partners: PartnerSummary[] | undefined = useSelector(selectPartners);
  const agents: AgentSummary[] | undefined = useSelector(selectAgents);
  const selectedReturn: ProductReturn | undefined = useSelector(selectSelectedReturn);
  const isAdmin: boolean = useSelector(isAdminRole);
  const isStorekeeper: boolean = useSelector(isStorekeeperRole);
  const canChangeStatus = isAdmin || isStorekeeper;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const addProductReturn = searchParams.get('addProductReturn');
  const addReturnPartnerId = addProductReturn ? parseInt(addProductReturn) : 0;

  const dispatch = useDispatch();
  const { formatDate } = useFormat();

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [editStatus, setEditStatus] = useState<ProductStatusValues | undefined>(undefined);
  const [returnCriteria, setReturnCriteria] = useState<LoadReturnCriteria>(defaultCriteria);

  useEffect(() => {
    if (productReturns === undefined) {
      dispatch(setProductReturns(null));
      setIsLoading(true);
      const access = new Access();
      const criteria: LoadProductReturnsRequest = {
        loadReturns: returnCriteria,
        loadPartnersAndAgents: {},
      };
      access
        .loadProductReturns(criteria)
        .then((value: LoadProductReturnsResponse | undefined) => {
          if (value?.error == null && value?.productReturns != null) {
            dispatch(setProductReturns(value.productReturns));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
          if (value?.partners) {
            dispatch(setPartners(value.partners));
          }
          if (value?.agents) {
            dispatch(setAgents(value.agents));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productReturns]);

  useEffect(() => {
    if (productReturns != null && !isLoading) {
      const access = new Access();
      const request: LoadProductReturnsRequest = {
        loadReturns: returnCriteria,
      };
      access
        .loadProductReturns(request)
        .then((value: LoadProductReturnsResponse | undefined) => {
          if (value?.error == null && value?.productReturns != null) {
            dispatch(setProductReturns(value.productReturns));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnCriteria]);

  useEffect(() => {
    return () => {
      dispatch(setProductReturns(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addReturnPartnerId !== 0) {
      const agent = searchParams.get('agent');
      const agentId = agent ? parseInt(agent) : 0;
      navigate('/productReturns');
      handleCreateReturn(addReturnPartnerId, agentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addReturnPartnerId]);

  const headCells: TableHeaderCell[] = [
    {
      id: 'date',
      isNumeric: false,
      label: 'Dátum',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'status',
      isNumeric: false,
      label: 'Státus',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'partner',
      isNumeric: false,
      label: 'Partner',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'product',
      isNumeric: false,
      label: 'Termék',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'productCount',
      isNumeric: true,
      label: 'Darabszám',
      disablePadding: false,
      width: '10%',
    },
    {
      id: 'reason',
      isNumeric: false,
      label: 'Visszaküldés oka',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'description',
      isNumeric: false,
      label: 'Hibaleírás',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'operation',
      isNumeric: false,
      label: '',
      disablePadding: true,
      width: '10%',
    },
  ];

  const setSelectedReturn = (productReturn: ProductReturn | undefined): void => {
    dispatch(setSelectedReturnAction(productReturn));
  };

  const saveReturn = (values: ProductReturnValues) => {
    if (values != null && values.product?.id != null) {
      const access = new Access();
      const { productCount, ...productReturn } = values;
      const storeProductReturn: StoreProductReturn = {
        ...productReturn,
        partnerId: values.partnerId as number,
        agentId: values.agentId as number,
        partnerCode: '',
        partnerName: '',
        photoUrls: [],
        date: values.date as any,
        deliveryDate:
          values.reason === ReturnReason.misorder || values.reason === ReturnReason.damagedOnDelivery
            ? (values.deliveryDate as any)
            : undefined,
        product: {
          ...values.product,
          count:
            productCount != null
              ? typeof productCount === 'string'
                ? parseInt(productCount)
                : productCount
              : undefined,
        },
      };

      const request: SaveProductReturnRequest = {
        storeProductReturn: storeProductReturn,
      };
      access
        .saveProductReturn(request)
        .then((value: SaveProductReturnResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setProductReturns(undefined));
            dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-myTarget' }));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsEditOpen(false);
          setSelectedReturn(undefined);
        });
    }
  };

  const changeStatus = (id: number, status: ReturnStatus, rejectionReason?: string): void => {
    const access = new Access();

    const changeStatusCriteria: ChangeStatusCriteria = {
      id,
      status,
      rejectionReason,
    };

    const request: SaveProductReturnRequest = {
      changeStatus: changeStatusCriteria,
    };
    access
      .saveProductReturn(request)
      .then((value: SaveProductReturnResponse | undefined) => {
        if (value?.error == null) {
          dispatch(setProductReturns(undefined));
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-myTarget' }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        setEditStatus(undefined);
      });
  };

  const handleChangeStatus = (productReturn: ProductReturn): void => {
    if (canChangeStatus) {
      switch (productReturn.status) {
        case ReturnStatus.new: {
          changeStatus(productReturn.id, ReturnStatus.inProgress);
          break;
        }
        case ReturnStatus.inProgress: {
          setEditStatus({ id: productReturn.id, status: 0, rejectionReason: undefined });
          break;
        }
      }
    }
  };

  const productReturnData: TableRowData[] = useMemo(
    () => {
      return (productReturns?.items ?? []).map((productReturn: ProductReturn) => {
        const date = formatDate(productReturn.date);
        return {
          rowId: `productReturn-${productReturn.id}`,
          id: productReturn.id,
          columns: [
            {
              id: 'date',
              cValue: productReturn.date,
              value: (
                <>
                  {date}
                  <br />
                  {productReturn.serialNumber}
                </>
              ),
            },
            {
              id: 'status',
              cValue: productReturn.status,
              value: (
                <>
                  <Status data={productReturn.status} />
                  {productReturn.rejectionReason && <Info text={productReturn.rejectionReason} />}
                </>
              ),
            },
            {
              id: 'partner',
              cValue: productReturn.partnerName,
              value: productReturn.partnerName,
            },
            {
              id: 'product',
              cValue: productReturn.product?.code,
              value: productReturn.product ? `${productReturn.product.code} - ${productReturn.product.name}` : '',
            },
            {
              id: 'productCount',
              cValue: productReturn.product?.count,
              value: <Box sx={{ mr: 1.5 }}>{productReturn.product ? productReturn.product.count : ''}</Box>,
            },
            {
              id: 'reason',
              cValue: productReturn.reason,
              value: <Reason data={productReturn.reason} />,
            },
            {
              id: 'description',
              cValue: productReturn.description,
              value: productReturn.description ? (
                <ReadMore text={productReturn.description} maxLength={100} variant="caption" />
              ) : (
                ''
              ),
            },
            {
              id: 'operation',
              cValue: '',
              value: (
                <>
                  {productReturn.photoUrls.length > 0 && (
                    <Gallery
                      title={`${date} ${productReturn.serialNumber}`}
                      images={productReturn.photoUrls.map((photo: string, key: number) => ({
                        id: key,
                        src: photo,
                        title: '',
                      }))}
                      size="small"
                    />
                  )}
                  {canChangeStatus ? <StatusButton data={productReturn} onClick={handleChangeStatus} /> : ''}
                </>
              ),
            },
          ],
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productReturns]
  );

  const handleChangeSearchTerm = (event: any) => {
    const searchTerm = event.target.value;
    setReturnCriteria({
      ...returnCriteria,
      searchTerm,
      page: 0,
    });
  };

  const handleChangeStatusFilter = (statuses: ReturnStatus[]): void => {
    setReturnCriteria({
      ...returnCriteria,
      statuses,
      page: 0,
    });
  };

  const handleChangeReasonFilter = (reasons: ReturnReason[]): void => {
    setReturnCriteria({
      ...returnCriteria,
      reasons,
      page: 0,
    });
  };

  const handleCreateReturn = (partnerId: number, agentId: number): void => {
    setIsEditOpen(true);
    setSelectedReturn({
      id: -1,
      serialNumber: '',
      date: new Date(),
      agentId,
      partnerId,
      partnerCode: '',
      partnerName: '',
      photoUrls: [],
      status: ReturnStatus.new,
      description: '',
    });
  };

  const handleCloseReturnReason = (values: ProductStatusValues): void => {
    changeStatus(values.id, values.status, values.rejectionReason);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {productReturns != null && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">
                  Visszárú
                </Typography>

                <Button
                  variant="contained"
                  sx={{ my: 2 }}
                  startIcon={<AddIcon sx={{ fill: '#fff' }} />}
                  color="primary"
                  onClick={() => handleCreateReturn(0, 0)}
                >
                  Új visszárú
                </Button>

                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                  <Box sx={{ flexGrow: 4 }}>
                    <StatusFilter selected={returnCriteria.statuses ?? []} setSelected={handleChangeStatusFilter} />
                  </Box>
                  <Box sx={{ flexGrow: 4, ml: 2 }}>
                    <ReasonFilter selected={returnCriteria.reasons ?? []} setSelected={handleChangeReasonFilter} />
                  </Box>
                  <TextField
                    id="search-term-tasks"
                    label="Szűrés"
                    variant="standard"
                    value={returnCriteria.searchTerm}
                    onChange={handleChangeSearchTerm}
                    size="small"
                    sx={{ flexGrow: 1, ml: 2, mt: 1.7 }}
                  />
                </Box>
                <TableRemote
                  id="returnProducts"
                  headCells={headCells}
                  rows={productReturnData}
                  hideOthersOnSelect={true}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultOrderBy={productReturns?.sortBy ?? ''}
                  defaultOrder={productReturns?.sortDirection ?? 'desc'}
                  total={productReturns?.total ?? 0}
                  currentPage={productReturns?.page ?? 0}
                  currentPageLength={productReturns?.pageSize ?? 0}
                  notFoundText="Nincsenek előző visszárúk."
                  onChangeSort={(orderBy: string, order: 'asc' | 'desc', pageLength: number) =>
                    setReturnCriteria({
                      ...returnCriteria,
                      sortBy: orderBy,
                      sortDirection: order,
                      pageSize: pageLength,
                    })
                  }
                  onChangePage={(page: number, pageLength: number, orderBy: string, order: 'asc' | 'desc') =>
                    setReturnCriteria({
                      ...returnCriteria,
                      page,
                      sortBy: orderBy,
                      sortDirection: order,
                      pageSize: pageLength,
                    })
                  }
                  onChangePageLength={(pageLength: number, orderBy: string, order: 'asc' | 'desc') =>
                    setReturnCriteria({
                      ...returnCriteria,
                      sortBy: orderBy,
                      sortDirection: order,
                      pageSize: pageLength,
                    })
                  }
                />

                <EditReturn
                  data={selectedReturn}
                  isOpen={isEditOpen}
                  onClose={() => {
                    setIsEditOpen(false);
                    setSelectedReturn(undefined);
                  }}
                  onSave={saveReturn}
                  partners={partners}
                  agents={isAdmin || isStorekeeper ? agents : []}
                />

                {editStatus && (
                  <EditReturnStatus
                    data={editStatus}
                    onClose={() => {
                      setEditStatus(undefined);
                    }}
                    onSave={handleCloseReturnReason}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
