import { useEffect, useState } from 'react';
import { Collapse, Grid, Typography, Theme, Divider, Box } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { PerformanceReference } from '../../common/statistics/PerformanceReference';
import { Purchases } from '../../common/statistics/Purchases';
import { ProductWidth } from '../../common/statistics/ProductWidth';
import { Purchases as PurchasesModel } from '../../../models/common/Purchases';
import { ProductWidth as ProductWidthModel } from '../../../models/common/ProductWidth';
import { PerformanceReference as PerformanceReferenceModel } from '../../../models/common/PerformanceReference';
import { TransactionTrend as TransactionTrendModel } from '../../../models/common/TransactionTrend';
import { PartnerDistribution } from './PartnerDistribution';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPerformanceReference,
  selectProductWidth,
  selectPurchases,
  selectTransactionTrend,
  setPerformanceReference,
  setProductWidth,
  setPurchases,
  setTransactionTrend,
} from '../../../slices/myTargetSlice';
import { Access } from '../../../access/Access';
import { MyTargetInfoRequest } from '../../../models/myTarget/MyTargetInfoRequest';
import { MyTargetInfoResponse } from '../../../models/myTarget/MyTargetInfoResponse';
import { addMessage } from '../../../slices/applicationSlice';
import { Loading } from '../../common/Loading';
import { Module } from '../../../models/Module';
import { TransactionTrend } from './TransactionTrend';

interface Props {
  agentId: number;
}

export const Performance: React.FC<Props> = ({ agentId }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isLoadingPurchases, setIsLoadingPurchases] = useState<boolean>(true);
  const [isLoadingTransactionTrend, setIsLoadingTransactionTrend] = useState<boolean>(true);
  const [isLoadingProductWidth, setIsLoadingProductWidth] = useState<boolean>(true);
  const [isLoadingPerformanceReference, setIsLoadingPerformanceReference] = useState<boolean>(true);
  const dispatch = useDispatch();

  const purchases: PurchasesModel | null | undefined = useSelector(selectPurchases);
  const transactionTrend: TransactionTrendModel | null | undefined = useSelector(selectTransactionTrend);
  const productWidth: ProductWidthModel | null | undefined = useSelector(selectProductWidth);
  const performanceReference: PerformanceReferenceModel[] | undefined | null = useSelector(selectPerformanceReference);

  useEffect(() => {
    if (purchases === undefined) {
      dispatch(setPurchases(null));
      setIsLoadingPurchases(true);
      const access = new Access();
      const criteria: MyTargetInfoRequest = {
        agentId,
        getPurchases: {},
      };
      access
        .myTargetInfo(criteria)
        .then((value: MyTargetInfoResponse | undefined) => {
          if (value?.error == null && value?.agentInfo?.purchases != null) {
            dispatch(setPurchases(value.agentInfo.purchases));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoadingPurchases(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, purchases]);

  useEffect(() => {
    if (productWidth === undefined) {
      dispatch(setProductWidth(null));
      setIsLoadingProductWidth(true);
      const access = new Access();
      const criteria: MyTargetInfoRequest = {
        agentId,
        getProductWidth: {},
      };
      access
        .myTargetInfo(criteria)
        .then((value: MyTargetInfoResponse | undefined) => {
          if (value?.error == null && value?.agentInfo?.productWidth != null) {
            dispatch(setProductWidth(value.agentInfo.productWidth));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoadingProductWidth(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, purchases]);

  useEffect(() => {
    if (transactionTrend === undefined) {
      dispatch(setTransactionTrend(null));
      setIsLoadingTransactionTrend(true);
      const access = new Access();
      const criteria: MyTargetInfoRequest = {
        agentId,
        getTransactionTrend: {},
      };
      access
        .myTargetInfo(criteria)
        .then((value: MyTargetInfoResponse | undefined) => {
          if (value?.error == null && value?.agentInfo?.transactionTrend != null) {
            dispatch(setTransactionTrend(value.agentInfo.transactionTrend));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoadingTransactionTrend(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, purchases]);

  useEffect(() => {
    if (performanceReference === undefined) {
      dispatch(setPerformanceReference(null));
      setIsLoadingPerformanceReference(true);
      const access = new Access();
      const criteria: MyTargetInfoRequest = {
        agentId,
        getPerformanceReference: {},
      };
      access
        .myTargetInfo(criteria)
        .then((value: MyTargetInfoResponse | undefined) => {
          if (value?.error == null && value?.agentInfo?.performanceReference != null) {
            dispatch(setPerformanceReference(value.agentInfo.performanceReference));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoadingPerformanceReference(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, performanceReference]);

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        Teljesítmény referencia
        {isCollapsed ? (
          <KeyboardArrowUpIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        )}
      </Typography>
      <Collapse in={!isCollapsed}>
        <Box mb={3}>
          <Grid container spacing={3}>
            {isLoadingPerformanceReference ? (
              <Grid item xs={12} md={6}>
                <Loading />
              </Grid>
            ) : (
              <>
                {performanceReference && (
                  <PerformanceReference data={performanceReference} agentId={agentId} module={Module.myTarget} />
                )}
              </>
            )}

            <PartnerDistribution agentId={agentId} />
          </Grid>

          {isLoadingTransactionTrend ? (
            <Loading />
          ) : (
            <>{transactionTrend && <TransactionTrend data={transactionTrend} />}</>
          )}

          {isLoadingPurchases ? (
            <Loading />
          ) : (
            <>{purchases && <Purchases data={purchases} module={Module.myTarget} />}</>
          )}
          {isLoadingProductWidth ? (
            <Loading />
          ) : (
            <>{productWidth && <ProductWidth data={productWidth} module={Module.myTarget} />}</>
          )}
        </Box>
      </Collapse>
      <Divider sx={{ mb: 3 }} />
    </>
  );
};
