import { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Box, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';

import { Bonus } from '../../../models/admin/Bonus';
import { setBonus, selectBonus } from '../../../slices/adminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Access } from '../../../access/Access';
import { AdminInfoRequest } from '../../../models/admin/AdminInfoRequest';
import { AdminInfoResponse } from '../../../models/admin/AdminInfoResponse';
import { addMessage } from '../../../slices/applicationSlice';
import { Loading } from '../../common/Loading';
import { Table, TableHeaderCell, TableRowData } from '../../common/Table';
import { useFormat } from '../../../utility/useFormat';
import { FormSelect } from '../../common/input/FormSelect';

interface BonusValues {
  year: number;
  quarter: number;
}
const now = new Date();
const currentQuarter = Math.floor((now.getMonth() + 3) / 3);

export const QuarterlyBonuses: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { formatNumber } = useFormat();
  let ref = useRef(null) as any;

  const bonus: Bonus[] | undefined | null = useSelector(selectBonus);

  const initialValues: BonusValues = {
    year: currentQuarter === 1 ? now.getFullYear() - 1 : now.getFullYear(),
    quarter: currentQuarter === 1 ? 4 : currentQuarter - 1,
  };

  const loadBonus = (year: number, quarter: number) => {
    dispatch(setBonus(null));
    setIsLoading(true);
    setIsInitialized(true);
    const access = new Access();
    const criteria: AdminInfoRequest = {
      getAgentsQuarterlyBonuses: { year, quarter },
    };
    access
      .adminInfo(criteria)
      .then((value: AdminInfoResponse | undefined) => {
        if (value?.error == null && value?.adminInfo?.agentsQuarterlyBonuses != null) {
          dispatch(setBonus(value.adminInfo.agentsQuarterlyBonuses));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (bonus === undefined) {
      loadBonus(ref?.values?.year ?? initialValues.year, ref?.values?.quarter ?? initialValues.quarter);
    } else if (bonus != null && !isInitialized) {
      dispatch(setBonus(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonus]);

  const handleSubmit = (value: BonusValues) => {
    loadBonus(value.year, value.quarter);
  };

  const yearOptions = useMemo(() => {
    const options = [];
    const currentYear = now.getFullYear();
    for (let i = currentYear - 2; i <= currentYear; i++) {
      options.push({
        key: i,
        value: i,
      });
    }
    return options;
  }, []);

  const quarterOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i <= 4; i++) {
      options.push({
        key: i,
        value: `Q${i}`,
      });
    }
    return options;
  }, []);

  const bonusData = useMemo(() => {
    if (bonus == null) return undefined;

    const data: TableRowData[] = [];
    let sumPointTarget = 0,
      sumAchievedPoints = 0,
      sumBonus = 0;

    bonus.forEach((s: Bonus, index: number) => {
      sumPointTarget += s.pointTarget;
      sumAchievedPoints += s.achievedPoints;
      sumBonus += s.bonus;
      data.push({
        id: index.toString(),
        rowId: index,
        columns: [
          {
            id: 'agent',
            value: s.label,
            cValue: s.label,
          },
          {
            id: 'pointTarget',
            value: formatNumber(s.pointTarget),
            cValue: s.pointTarget,
          },
          {
            id: 'achievedPoints',
            value: formatNumber(s.achievedPoints),
            cValue: s.achievedPoints,
          },
          {
            id: 'fulfillment',
            value: formatNumber(s.fulfillment, '%', 2),
            cValue: s.fulfillment,
          },
          {
            id: 'bonus',
            value: formatNumber(s.bonus),
            cValue: s.bonus,
          },
        ],
      });
    });
    data.push({
      id: 'total',
      rowId: 'total',
      columns: [
        {
          id: 'agent',
          value: 'Teljes',
        },
        {
          id: 'pointTarget',
          value: formatNumber(sumPointTarget),
        },
        {
          id: 'achievedPoints',
          value: formatNumber(sumAchievedPoints),
        },
        {
          id: 'fulfillment',
          value: 'N/A',
        },
        {
          id: 'bonus',
          value: formatNumber(sumBonus),
        },
      ],
      sx: { '>td': { fontWeight: 'bold' } },
    });
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonus]);

  return (
    <Box mx={3} my={3}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={(instance) => {
          ref = instance;
        }}
      >
        {({ values, submitForm }) => {
          const bonusCells: TableHeaderCell[] = [
            {
              id: 'agent',
              disablePadding: true,
              isNumeric: false,
              label: 'Értékesítő',
              width: '20%',
            },
            {
              id: 'pointTarget',
              disablePadding: true,
              isNumeric: true,
              label: `'${values?.year - 2000} Q${values?.quarter} pontcél`,
              width: '20%',
            },
            {
              id: 'achievedPoints',
              disablePadding: true,
              isNumeric: true,
              label: 'Elért pontok',
              width: '20%',
            },
            {
              id: 'fulfillment',
              disablePadding: true,
              isNumeric: true,
              label: 'Időarányos teljesítés bónusz',
              width: '20%',
            },
            {
              id: 'bonus',
              disablePadding: true,
              isNumeric: true,
              label: 'Becsült bónusz',
              width: '20%',
            },
          ];
          return (
            <>
              <Form>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Pont cél teljesülése (Bónusz alap)
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: { xs: '100%', md: '40%', lg: '30%' },
                  }}
                >
                  <FormControl fullWidth sx={{ mt: 1, mr: 2 }}>
                    <InputLabel id="year-label" sx={{ ml: -2 }}>
                      Év
                    </InputLabel>
                    <FormSelect labelId="year-label" name="year" id="year" variant="standard" onChange={submitForm}>
                      {(yearOptions ?? []).map((option) => (
                        <MenuItem value={option.key} key={option.key}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  </FormControl>
                  -
                  <FormControl fullWidth sx={{ mt: 1, ml: 2 }}>
                    <InputLabel id="quarter-label" sx={{ ml: -2 }}>
                      Negyedév
                    </InputLabel>
                    <FormSelect
                      labelId="quarter-label"
                      name="quarter"
                      id="quarter"
                      variant="standard"
                      onChange={submitForm}
                    >
                      {(quarterOptions ?? []).map((option) => (
                        <MenuItem
                          value={option.key}
                          key={option.key}
                          disabled={values.year === yearOptions[0].key && option.key < currentQuarter}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  </FormControl>
                </Box>
              </Form>

              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {bonusData != null && (
                    <Box mt={3}>
                      <Table
                        id="quarterlyBonuses"
                        headCells={bonusCells}
                        rows={bonusData}
                        hideOthersOnSelect={false}
                        selected={undefined}
                        setSelected={() => {}}
                        hidePaper
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          );
        }}
      </Formik>
    </Box>
  );
};
