import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Reason, getReason } from './Reason';
import { Input } from '@mui/material';
import React from 'react';
import { ReturnReason } from '../../../models/productReturns/ReturnReason';

interface Props {
  selected: ReturnReason[];
  setSelected: (values: ReturnReason[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const ReasonFilter: React.FC<Props> = ({ selected, setSelected }: Props) => {
  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    setSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',').map((x: string) => value as unknown as ReturnReason) : value
    );
  };

  return (
    <div>
      <FormControl sx={{ my: 3 }} fullWidth>
        <InputLabel id="reason-label">Visszaküldés oka szűrő</InputLabel>
        <Select
          labelId="reason-label"
          id="return-reason-filter"
          multiple
          variant="standard"
          value={selected}
          onChange={handleChange}
          input={<Input id="select-multiple-reason" />}
          renderValue={(values) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {values.map((value: ReturnReason) => (
                <React.Fragment key={value}>
                  <Reason data={value} />
                </React.Fragment>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {Object.keys(ReturnReason)
            .filter((key: any) => !isNaN(Number(ReturnReason[key])))
            .map((key) => {
              const value = (ReturnReason as any)[key];
              const status = getReason(value);
              return (
                <MenuItem key={value} value={value}>
                  {status!.label}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};
