import { Box, Fade, IconButton, ImageList, ImageListItem, Modal, Popover, Typography } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import { useMemo, useState } from 'react';
import { useMobile } from '../../utility/useMobile';

interface Props {
  images: ImageData[];
  title: string;
  size?: 'inherit' | 'large' | 'medium' | 'small';
}

export interface ImageData {
  id: number;
  src: string;
  title: string;
}

export const Gallery: React.FC<Props> = ({ title, images, size }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const [image, setImage] = useState<ImageData | undefined>(undefined);
  const isMobile = useMobile();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'gallery-popover' : undefined;

  const onCloseGallery = (): void => {
    if (image != null) {
      setImage(undefined);
    } else setOpenGallery(false);
  };

  const showNextImage = (img: ImageData): void => {
    const item = images.findIndex((i) => i.id === img.id);

    if (item > -1) {
      let newIndex = item + 1;

      if (newIndex >= images.length) newIndex = 0;
      setImage(images[newIndex]);
    }
  };
  const colProps: { col: number; rowHeight: number | 'auto' | undefined } = useMemo(() => {
    const maxCol = isMobile ? 2 : 4;
    let col = maxCol;
    let rowHeight: number | 'auto' | undefined = 242;
    if (images.length < maxCol) {
      col = images.length;
      rowHeight = 450;
    }

    return { col, rowHeight };
  }, [images, isMobile]);

  return images.length > 0 ? (
    <>
      <IconButton
        color="primary"
        aria-describedby={id}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onClick={() => setOpenGallery(!openGallery)}
        aria-haspopup="true"
        aria-owns={open ? id : undefined}
      >
        <CollectionsIcon fontSize={size ?? 'large'} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
        sx={{
          pointerEvents: 'none',
        }}
      >
        <Typography sx={{ p: 2 }} whiteSpace="pre-line">
          {title}
        </Typography>
      </Popover>

      <Modal
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        open={openGallery}
        onClose={onCloseGallery}
        closeAfterTransition
      >
        <Fade in={openGallery} timeout={500} style={{ outline: 'none', background: '#fff', padding: 16 }}>
          {image != null ? (
            <img
              src={image.src}
              alt="Zoomed"
              style={{ maxHeight: '90%', maxWidth: '90%', cursor: 'pointer' }}
              onClick={() => showNextImage(image)}
            />
          ) : images.length === 1 ? (
            <Box>
              <Typography variant="h5" sx={{ mb: 1 }}>
                {title}
              </Typography>
              <img src={images[0].src} alt="Zoomed" style={{ maxHeight: '90%', maxWidth: '90%' }} />
            </Box>
          ) : (
            <Box sx={{ maxHeight: '90%', overflowY: 'auto', overflowX: 'initial', width: { xs: '90%', md: '60%' } }}>
              <Typography variant="h5">{title}</Typography>

              <ImageList
                sx={{ minHeight: 450, maxHeight: '100%', width: '100%' }}
                variant="quilted"
                cols={colProps.col}
                rowHeight={colProps.rowHeight}
              >
                {images.map((item: ImageData) => (
                  <ImageListItem key={item.id} onClick={() => setImage(item)} sx={{ p: 1 }}>
                    <img src={item.src} alt={item.title} loading="lazy" style={{ cursor: 'pointer' }} />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          )}
        </Fade>
      </Modal>
    </>
  ) : null;
};
