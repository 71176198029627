import { ReactElement } from 'react';
import { Chip } from '@mui/material';
import { ReturnStatus } from '../../../models/productReturns/ReturnStatus';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

interface Props {
  data: ReturnStatus | undefined;
  onDelete?: (id: ReturnStatus) => void;
}

export const getStatus = (
  status: ReturnStatus | undefined
): { label: string; color: any; icon: ReactElement } | undefined => {
  if (status == null) return undefined;
  switch (status) {
    case ReturnStatus.new:
      return {
        label: 'Új',
        color: 'secondary',
        icon: <GradeOutlinedIcon sx={{ backgroundColor: 'transparent !important' }} />,
      };
    case ReturnStatus.inProgress:
      return {
        label: 'Folyamatban',
        color: 'primary',
        icon: <LoopIcon sx={{ backgroundColor: 'transparent !important' }} />,
      };
    case ReturnStatus.closed:
      return { label: 'Lezárva - Elfogadva', color: 'default', icon: <DoneIcon /> };
    case ReturnStatus.rejected:
      return { label: 'Visszautasítva', color: 'default', icon: <CloseIcon /> };
  }
};

export const Status: React.FC<Props> = ({ data, onDelete }: Props) => {
  const status = getStatus(data);
  return data && status ? (
    <Chip
      size="small"
      avatar={status.icon}
      sx={{ my: 0.3, minWidth: 105 }}
      color={status.color}
      label={status.label}
      onDelete={
        onDelete != null
          ? () => {
              onDelete(data);
            }
          : undefined
      }
    />
  ) : null;
};
