import React from 'react';
import { useField } from 'formik';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormHelperText, FormLabel } from '@mui/material';

export const FormRadioGroup = ({ name, options, ...props }: any) => {
  const [field, meta] = useField(name);
  const fieldName = name || field.name;
  const { label } = props;
  return (
    <>
      {label && (
        <FormLabel htmlFor={fieldName} style={{ fontWeight: 'normal' }}>
          {label}
        </FormLabel>
      )}
      <RadioGroup {...field} {...props} name={fieldName}>
        {options.map((option: any) => (
          <FormControlLabel
            value={option.key}
            control={<Radio color="primary" />}
            label={option.value}
            labelPlacement={option.labelPlacement ?? 'end'}
            key={option.key}
            className={meta.touched && meta.error ? 'error' : ''}
          />
        ))}
      </RadioGroup>
      {meta.touched && meta.error && <FormHelperText error={!!meta.error}>{meta.error}</FormHelperText>}
    </>
  );
};
