import { useEffect, useMemo, useRef, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';

import { AgentsDetailedIndicators as AgentsDetailedIndicatorsModel } from '../../../models/admin/AgentsDetailedIndicators';
import { setAgentsDetailedIndicators, selectAgentsDetailedIndicators } from '../../../slices/adminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Access } from '../../../access/Access';
import { AdminInfoRequest } from '../../../models/admin/AdminInfoRequest';
import { AdminInfoResponse } from '../../../models/admin/AdminInfoResponse';
import { addMessage } from '../../../slices/applicationSlice';
import { Loading } from '../../common/Loading';
import { Table, TableHeaderCell, TableRowData } from '../../common/Table';
import { AgentResult } from '../../../models/admin/AgentResult';
import { useFormat } from '../../../utility/useFormat';
import { BuyerHabit } from '../../../models/admin/BuyerHabit';
import { PortfolioDevelopment } from '../../../models/admin/PortfolioDevelopment';
import { AgentsActivity } from '../../../models/admin/AgentsActivity';
import { Period } from '../../../models/common/Period';
import { Form, Formik } from 'formik';
import { FormDateRangePicker } from '../../common/input/FormDateRangePicker';

interface FilterValues {
  start: {
    from: Date;
    to: Date;
  };
  end: {
    from: Date;
    to: Date;
  };
}

const startFrom = new Date();
startFrom.setDate(0);
startFrom.setDate(1); // 1 will result in the first day of the month

const startTo = new Date();
startTo.setDate(0); // 0 will result in the last day of the previous month

const endFrom = new Date();
endFrom.setFullYear(endFrom.getFullYear() - 1);
endFrom.setDate(0);
endFrom.setDate(1);
const endTo = new Date();
endTo.setFullYear(endTo.getFullYear() - 1);
endTo.setDate(1);
endTo.setHours(-1);

export const AgentsDetailedIndicators: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { formatNumber, formatDate } = useFormat();
  let ref = useRef(null) as any;

  const agentsDetailedIndicators: AgentsDetailedIndicatorsModel | undefined | null =
    useSelector(selectAgentsDetailedIndicators);

  const loadDetailedIndicators = (start: Period, end: Period): void => {
    dispatch(setAgentsDetailedIndicators(null));
    setIsLoading(true);
    setIsInitialized(true);
    const access = new Access();
    const criteria: AdminInfoRequest = {
      getAgentsDetailedIndicators: { start, end },
    };
    access
      .adminInfo(criteria)
      .then((value: AdminInfoResponse | undefined) => {
        if (value?.error == null && value?.adminInfo?.agentsDetailedIndicators != null) {
          dispatch(setAgentsDetailedIndicators(value.adminInfo.agentsDetailedIndicators));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const initialValues: FilterValues = {
    start: {
      from: startFrom,
      to: startTo,
    },
    end: {
      from: endFrom,
      to: endTo,
    },
  };

  useEffect(() => {
    if (agentsDetailedIndicators === undefined) {
      const start: Period = {
        from: formatDate(ref?.values?.start?.from ?? initialValues.start.from, 'YYYY-MM-DDT00:00:00.000[Z]'),
        to: formatDate(ref?.values?.start?.to ?? initialValues.start.to, 'YYYY-MM-DDT23:59:59.000[Z]'),
      };
      const end: Period = {
        from: formatDate(ref?.values?.end?.from ?? initialValues.end.from, 'YYYY-MM-DDT00:00:00.000[Z]'),
        to: formatDate(ref?.values?.end?.to ?? initialValues.end.to, 'YYYY-MM-DDT23:59:59.000[Z]'),
      };
      loadDetailedIndicators(start, end);
    } else if (agentsDetailedIndicators != null && !isInitialized) {
      dispatch(setAgentsDetailedIndicators(undefined));
    }
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentsDetailedIndicators]);

  const handleSubmit = (values: FilterValues): void => {
    const start: Period = {
      from: formatDate(values.start.from, 'YYYY-MM-DDT00:00:00.000[Z]'),
      to: formatDate(values.start.to, 'YYYY-MM-DDT23:59:59.000[Z]'),
    };
    const end: Period = {
      from: formatDate(values.end.from, 'YYYY-MM-DDT00:00:00.000[Z]'),
      to: formatDate(values.end.to, 'YYYY-MM-DDT23:59:59.000[Z]'),
    };
    loadDetailedIndicators(start, end);
  };

  const detailedIndicators = useMemo(() => {
    if (agentsDetailedIndicators == null) return undefined;
    const resultData: TableRowData[] = [
      {
        id: 'result-income',
        rowId: 'result-income',
        columns: [
          {
            id: 'header',
            value: 'Árbevétel (Ft)',
          },
        ],
      },
      {
        id: 'result-income-change',
        rowId: 'result-income-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'result-income-contribution',
        rowId: 'result-income-contribution',
        columns: [
          {
            id: 'header',
            value: 'Árbevétel kontribució (%)',
          },
        ],
      },
      {
        id: 'result-margin',
        rowId: 'result-margin',
        columns: [
          {
            id: 'header',
            value: 'Marzs (Ft)',
          },
        ],
      },
      {
        id: 'result-margin-change',
        rowId: 'result-margin-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'result-margin-percent',
        rowId: 'result-margin-percent',
        columns: [
          {
            id: 'header',
            value: 'Marzs (%)',
          },
        ],
      },
      {
        id: 'result-margin-contribution',
        rowId: 'result-margin-contribution',
        columns: [
          {
            id: 'header',
            value: 'Marzs kontribució (%)',
          },
        ],
      },
      {
        id: 'result-point',
        rowId: 'result-point',
        columns: [
          {
            id: 'header',
            value: 'Pont',
          },
        ],
      },

      {
        id: 'result-point-change',
        rowId: 'result-point-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
    ];
    const resultCells: TableHeaderCell[] = [
      {
        id: 'header',
        isNumeric: false,
        label: '',
        disablePadding: true,
        disableOrdering: true,
        width: '15%',
      },
    ];

    const buyerHabitsCells: TableHeaderCell[] = [
      {
        id: 'header',
        isNumeric: false,
        label: '',
        disablePadding: true,
        disableOrdering: true,
        width: '15%',
      },
    ];
    const buyerHabitsData: TableRowData[] = [
      {
        id: 'buyer-habit-purchase-frequency',
        rowId: 'buyer-habit-purchase-frequency',
        columns: [
          {
            id: 'header',
            value: 'Vásárlási gyakoriság (db)',
          },
        ],
      },
      {
        id: 'buyer-habit-purchase-frequency-change',
        rowId: 'buyer-habit-purchase-frequency-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'buyer-habit-cart-size',
        rowId: 'buyer-habit-cart-size',
        columns: [
          {
            id: 'header',
            value: 'Kosárméret (Ft)',
          },
        ],
      },
      {
        id: 'buyer-habit-cart-size-change',
        rowId: 'buyer-habit-cart-size-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },

      {
        id: 'buyer-habit-product-width',
        rowId: 'buyer-habit-product-width',
        columns: [
          {
            id: 'header',
            value: 'Penetráció (cikk)',
          },
        ],
      },

      {
        id: 'buyer-habit-product-width-change',
        rowId: 'buyer-habit-product-width-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
    ];

    const portfolioDevelopmentCells: TableHeaderCell[] = [
      {
        id: 'header',
        isNumeric: false,
        label: '',
        disablePadding: true,
        disableOrdering: true,
        width: '15%',
      },
    ];
    const portfolioDevelopmentData: TableRowData[] = [
      {
        id: 'portfolio-development-total-stores',
        rowId: 'portfolio-development-total-stores',
        columns: [
          {
            id: 'header',
            value: 'Összes boltok száma (db)',
          },
        ],
      },
      {
        id: 'portfolio-development-active-stores',
        rowId: 'portfolio-development-active-stores',
        columns: [
          {
            id: 'header',
            value: 'Ebből Aktív boltok száma (db)',
          },
        ],
      },
      {
        id: 'portfolio-development-active-stores-percent',
        rowId: 'portfolio-development-active-stores-percent',
        columns: [
          {
            id: 'header',
            value: 'Aktív boltok aránya (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-passive-stores',
        rowId: 'portfolio-development-passive-stores',
        columns: [
          {
            id: 'header',
            value: 'Ebből Passzív boltok száma (db)',
          },
        ],
      },
      {
        id: 'portfolio-development-passive-stores-percent',
        rowId: 'portfolio-development-passive-stores-percent',
        columns: [
          {
            id: 'header',
            value: 'Passzív boltok aránya (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-stores-empty-line',
        rowId: 'portfolio-development-stores-empty-line',
        columns: [
          {
            id: 'header',
            value: '',
          },
        ],
      },
      {
        id: 'portfolio-development-active-stores-ratio',
        rowId: 'portfolio-development-active-stores-ratio',
        columns: [
          {
            id: 'header',
            value: <strong>Aktív boltok megoszlása</strong>,
          },
        ],
      },
      {
        id: 'portfolio-development-active-stores',
        rowId: 'portfolio-development-active-stores',
        columns: [
          {
            id: 'header',
            value: 'Aktív - Normál (db)',
          },
        ],
      },
      {
        id: 'portfolio-development-active-stores-percent',
        rowId: 'portfolio-development-active-stores-percent',
        columns: [
          {
            id: 'header',
            value: 'Arány (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-active-stores-change',
        rowId: 'portfolio-development-active-stores-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-new-stores',
        rowId: 'portfolio-development-new-stores',
        columns: [
          {
            id: 'header',
            value: 'Aktív - Új bolt (db)',
          },
        ],
      },
      {
        id: 'portfolio-development-new-stores-percent',
        rowId: 'portfolio-development-new-stores-percent',
        columns: [
          {
            id: 'header',
            value: 'Arány (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-new-stores-change',
        rowId: 'portfolio-development-new-stores-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-reactivated-stores',
        rowId: 'portfolio-development-reactivated-stores',
        columns: [
          {
            id: 'header',
            value: 'Aktív - Újra aktivált (db)',
          },
        ],
      },
      {
        id: 'portfolio-development-reactivated-stores-percent',
        rowId: 'portfolio-development-reactivated-stores-percent',
        columns: [
          {
            id: 'header',
            value: 'Arány (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-reactivated-stores-change',
        rowId: 'portfolio-development-reactivated-stores-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-under-performing-stores',
        rowId: 'portfolio-development-under-performing-stores',
        columns: [
          {
            id: 'header',
            value: 'Aktív - Alul teljesítő boltok (db)',
          },
        ],
      },
      {
        id: 'portfolio-development-under-performing-stores-percent',
        rowId: 'portfolio-development-under-performing-stores-percent',
        columns: [
          {
            id: 'header',
            value: 'Arány (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-under-performing-stores-change',
        rowId: 'portfolio-development-under-performing-stores-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-attribution-stores',
        rowId: 'portfolio-development-attribution-stores',
        columns: [
          {
            id: 'header',
            value: 'Aktív - Lemorzsolódás veszélyes boltok (db)',
          },
        ],
      },
      {
        id: 'portfolio-development-attribution-stores-percent',
        rowId: 'portfolio-development-attribution-stores-percent',
        columns: [
          {
            id: 'header',
            value: 'Arány (%)',
          },
        ],
      },
      {
        id: 'portfolio-development-attribution-stores-change',
        rowId: 'portfolio-development-attribution-stores-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
    ];

    const agentsActivityCells: TableHeaderCell[] = [
      {
        id: 'header',
        isNumeric: false,
        label: '',
        disablePadding: true,
        disableOrdering: true,
        width: '15%',
      },
    ];
    const agentsActivityData: TableRowData[] = [
      {
        id: 'agents-activity-new-target',
        rowId: 'agents-activity-new-target',
        columns: [
          {
            id: 'header',
            value: 'Új célpontok (db)',
          },
        ],
      },
      {
        id: 'agents-activity-new-target-change',
        rowId: 'agents-activity-new-target-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'agents-activity-web-registered',
        rowId: 'agents-activity-web-registered',
        columns: [
          {
            id: 'header',
            value: 'Weben regisztrált, de nem vásárolt boltok közül meglátogatott',
          },
        ],
      },
      {
        id: 'agents-activity-web-registered-change',
        rowId: 'agents-activity-web-registered-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'agents-activity-morning-start',
        rowId: 'agents-activity-morning-start',
        columns: [
          {
            id: 'header',
            value: 'Reggeli átlagos indulás (óra)',
          },
        ],
      },
      {
        id: 'agents-activity-morning-start-change',
        rowId: 'agents-activity-morning-start-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'agents-activity-work-day-end',
        rowId: 'agents-activity-work-day-end',
        columns: [
          {
            id: 'header',
            value: 'Munkaidő befejezése (óra)',
          },
        ],
      },
      {
        id: 'agents-activity-work-day-end-change',
        rowId: 'agents-activity-work-day-end-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'agents-activity-daily-visits',
        rowId: 'agents-activity-daily-visits',
        columns: [
          {
            id: 'header',
            value: 'Napi átlagos bolt látogatás (db)',
          },
        ],
      },
      {
        id: 'agents-activity-daily-visits-change',
        rowId: 'agents-activity-daily-visits-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'agents-activity-time-spent-in-store',
        rowId: 'agents-activity-time-spent-in-store',
        columns: [
          {
            id: 'header',
            value: 'Boltban töltött idő (óra)',
          },
        ],
      },
      {
        id: 'agents-activity-time-spent-in-store-change',
        rowId: 'agents-activity-time-spent-in-store-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
      {
        id: 'agents-activity-kms-driven',
        rowId: 'agents-activity-kms-driven',
        columns: [
          {
            id: 'header',
            value: 'Megtett km (km)',
          },
        ],
      },
      {
        id: 'agents-activity-kms-driven-change',
        rowId: 'agents-activity-kms-driven-change',
        columns: [
          {
            id: 'header',
            value: 'Változás (%)',
          },
        ],
      },
    ];

    agentsDetailedIndicators.result.forEach((r: AgentResult, index: number) => {
      resultCells.push({
        id: index.toString(),
        isNumeric: false,
        label: r.label,
        disablePadding: true,
        disableOrdering: true,
      });
      resultData[0].columns.push({ id: index.toString(), value: formatNumber(r.income) }); //Árbevétel (Ft)
      resultData[1].columns.push({ id: index.toString(), value: formatNumber(r.incomeChange, '%', 2) }); //Változás (%)
      resultData[2].columns.push({ id: index.toString(), value: formatNumber(r.incomeContribution, '%', 2) }); //Árbevétel kontribució (%)
      resultData[3].columns.push({ id: index.toString(), value: formatNumber(r.margin) }); //Marzs (Ft)
      resultData[4].columns.push({ id: index.toString(), value: formatNumber(r.marginChange, '%', 2) }); //Változás (%)
      resultData[5].columns.push({ id: index.toString(), value: formatNumber(r.marginPercent, '%', 2) }); //Marzs (%)
      resultData[6].columns.push({ id: index.toString(), value: formatNumber(r.marginContribution, '%', 2) }); //Marzs kontribució (%)
      resultData[7].columns.push({ id: index.toString(), value: formatNumber(r.point) }); //Pont
      resultData[8].columns.push({ id: index.toString(), value: formatNumber(r.pointChange, '%', 2) }); // pont Változás (%)
    });

    agentsDetailedIndicators.buyerHabits.forEach((r: BuyerHabit, index: number) => {
      buyerHabitsCells.push({
        id: index.toString(),
        isNumeric: false,
        label: r.label,
        disablePadding: true,
        disableOrdering: true,
      });
      buyerHabitsData[0].columns.push({ id: index.toString(), value: formatNumber(r.purchaseFrequency, '', 1) }); //Vásárlási gyakoriság (Ft)
      buyerHabitsData[1].columns.push({ id: index.toString(), value: formatNumber(r.purchaseFrequencyChange, '%', 2) }); //Változás (%)
      buyerHabitsData[2].columns.push({ id: index.toString(), value: formatNumber(r.cartSize) }); //Kosárméret (Ft)
      buyerHabitsData[3].columns.push({ id: index.toString(), value: formatNumber(r.cartSizeChange, '%', 2) }); //Változás (%)
      buyerHabitsData[4].columns.push({ id: index.toString(), value: formatNumber(r.productWidth) }); //Penetráció (cikk)
      buyerHabitsData[5].columns.push({ id: index.toString(), value: formatNumber(r.productWidthChange, '%', 2) }); //Változás (%)
    });

    agentsDetailedIndicators.portfolioDevelopment.forEach((r: PortfolioDevelopment, index: number) => {
      portfolioDevelopmentCells.push({
        id: index.toString(),
        isNumeric: false,
        label: r.label,
        disablePadding: true,
        disableOrdering: true,
      });
      portfolioDevelopmentData[0].columns.push({ id: index.toString(), value: formatNumber(r.totalStores) }); //Összes boltok száma (db)
      portfolioDevelopmentData[1].columns.push({ id: index.toString(), value: formatNumber(r.totalActiveStores) }); //Aktív boltok száma (db)
      portfolioDevelopmentData[2].columns.push({
        id: index.toString(),
        value: formatNumber(r.totalActiveStoresPercent, '%', 2),
      }); //Aktiv boltok aranya (%)
      portfolioDevelopmentData[3].columns.push({ id: index.toString(), value: formatNumber(r.totalPassiveStores) }); //Passzív boltok száma (db)
      portfolioDevelopmentData[4].columns.push({
        id: index.toString(),
        value: formatNumber(r.totalPassiveStoresPercent, '%', 2),
      }); //Passzív boltok aranya (%)
      portfolioDevelopmentData[5].columns.push({ id: index.toString(), value: '' });
      portfolioDevelopmentData[6].columns.push({ id: index.toString(), value: formatNumber(r.totalActiveStores) });

      portfolioDevelopmentData[7].columns.push({ id: index.toString(), value: formatNumber(r.activeStores) }); //Aktív - Normál (db)
      portfolioDevelopmentData[8].columns.push({
        id: index.toString(),
        value: formatNumber(r.activeStoresPercent, '%', 0),
      }); //Aktív - Normál Arány (%)
      portfolioDevelopmentData[9].columns.push({
        id: index.toString(),
        value: formatNumber(r.activeStoresChange, '%', 2),
      }); //Aktív - Normál Változás (%)

      portfolioDevelopmentData[10].columns.push({ id: index.toString(), value: formatNumber(r.newStores) }); //Aktív - Új bolt (db)
      portfolioDevelopmentData[11].columns.push({
        id: index.toString(),
        value: formatNumber(r.newStoresPercent, '%', 0),
      }); //Aktív - Új bolt Arány (%)
      portfolioDevelopmentData[12].columns.push({
        id: index.toString(),
        value: formatNumber(r.newStoresChange, '%', 2),
      }); //Aktív - Új bolt - Változás (%)

      portfolioDevelopmentData[13].columns.push({ id: index.toString(), value: formatNumber(r.reaActivatedStores) }); //Aktív - Újra aktivált (db)
      portfolioDevelopmentData[14].columns.push({
        id: index.toString(),
        value: formatNumber(r.reaActivatedStoresPercent, '%', 0),
      }); //Aktív - Újra aktivált Arány (%)
      portfolioDevelopmentData[15].columns.push({
        id: index.toString(),
        value: formatNumber(r.reaActivatedStoresChange, '%', 2),
      }); //Aktív - Újra aktivált Változás (%)

      portfolioDevelopmentData[16].columns.push({ id: index.toString(), value: formatNumber(r.underPerformingStores) }); //Aktív - Alul teljesítő boltok (db)
      portfolioDevelopmentData[17].columns.push({
        id: index.toString(),
        value: formatNumber(r.underPerformingStoresPercent, '%', 0),
      }); //Aktív - Alul teljesítő Arány (%)
      portfolioDevelopmentData[18].columns.push({
        id: index.toString(),
        value: formatNumber(r.underPerformingStoresChange, '%', 2),
      }); //Aktív - Alul teljesítő Változás (%)

      portfolioDevelopmentData[19].columns.push({ id: index.toString(), value: formatNumber(r.attritionStores) }); //Aktív - Lemorzsolódás veszélyes boltok (db)
      portfolioDevelopmentData[20].columns.push({
        id: index.toString(),
        value: formatNumber(r.attritionStoresPercent, '%', 0),
      }); //Aktív - Lemorzsolódás veszélyes Arány (%)
      portfolioDevelopmentData[21].columns.push({
        id: index.toString(),
        value: formatNumber(r.attritionStoresChange, '%', 2),
      }); //Aktív - Lemorzsolódás veszélyes Változás (%)
    });

    agentsDetailedIndicators.agentsActivity.forEach((r: AgentsActivity, index: number) => {
      agentsActivityCells.push({
        id: index.toString(),
        isNumeric: false,
        label: r.label,
        disablePadding: true,
        disableOrdering: true,
      });
      agentsActivityData[0].columns.push({ id: index.toString(), value: formatNumber(r.newTargets) }); //Új célpontok (db)
      agentsActivityData[1].columns.push({
        id: index.toString(),
        value: formatNumber(r.newTargetsChange, '%', 2),
      }); //Változás (%)
      agentsActivityData[2].columns.push({ id: index.toString(), value: formatNumber(r.webRegistered) }); //Weben regisztrált, de nem vásárolt boltok közül meglátogatott (%)
      agentsActivityData[3].columns.push({
        id: index.toString(),
        value: formatNumber(r.webRegisteredChange, '%', 2),
      }); //Változás (%)
      agentsActivityData[4].columns.push({ id: index.toString(), value: r.morningStart }); //Reggeli átlagos indulás (óra)
      agentsActivityData[5].columns.push({
        id: index.toString(),
        value: formatNumber(r.morningStartChange, '%', 2),
      }); //Változás (%)
      agentsActivityData[6].columns.push({ id: index.toString(), value: r.workDayEnd }); //Munkaidő befejezése (óra)
      agentsActivityData[7].columns.push({
        id: index.toString(),
        value: formatNumber(r.workDayEndChange, '%', 2),
      }); //Változás (%)
      agentsActivityData[8].columns.push({ id: index.toString(), value: formatNumber(r.dailyVisits) }); //Napi átlagos bolt látogatás (db)
      agentsActivityData[9].columns.push({
        id: index.toString(),
        value: formatNumber(r.dailyVisitsChange, '%', 2),
      }); //Változás (%)
      agentsActivityData[10].columns.push({ id: index.toString(), value: formatNumber(r.hoursSpentInStores, '', 1) }); //Boltban töltött idő (óra)
      agentsActivityData[11].columns.push({
        id: index.toString(),
        value: formatNumber(r.hoursSpentInStoresChange, '%', 2),
      }); //Változás (%)
      agentsActivityData[12].columns.push({ id: index.toString(), value: formatNumber(r.kmsDriven) }); //Megtett km (km)
      agentsActivityData[13].columns.push({
        id: index.toString(),
        value: formatNumber(r.kmsDrivenChange, '%', 2),
      }); //Változás (%)
    });

    return {
      result: { cells: resultCells, data: resultData },
      buyerHabits: { cells: buyerHabitsCells, data: buyerHabitsData },
      portfolioDevelopment: { cells: portfolioDevelopmentCells, data: portfolioDevelopmentData },
      agentsActivity: { cells: agentsActivityCells, data: agentsActivityData },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentsDetailedIndicators]);
  return (
    <Box mx={3} my={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Részletes teljesítmény mutatók
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            innerRef={(instance) => {
              ref = instance;
            }}
          >
            {({ values }) => (
              <Form>
                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <FormDateRangePicker
                      nameFrom="start.from"
                      nameTo="start.to"
                      popOver={true}
                      onClose={() => handleSubmit(values)}
                      label="Időszak"
                    />
                  </Box>
                  <Box sx={{ ml: 3 }}>
                    <FormDateRangePicker
                      nameFrom="end.from"
                      nameTo="end.to"
                      popOver={true}
                      onClose={() => handleSubmit(values)}
                      label="Bázis időszak"
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {agentsDetailedIndicators != null && detailedIndicators != null && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1">Eredmények</Typography>
                  <Table
                    id="detailed-indicators"
                    headCells={detailedIndicators.result.cells}
                    rows={detailedIndicators.result.data}
                    hideOthersOnSelect={false}
                    selected={undefined}
                    setSelected={() => {}}
                    hidePaper
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Vásárlási szokások
                  </Typography>
                  <Table
                    id="buyer-habits"
                    headCells={detailedIndicators.buyerHabits.cells}
                    rows={detailedIndicators.buyerHabits.data}
                    hideOthersOnSelect={false}
                    selected={undefined}
                    setSelected={() => {}}
                    hidePaper
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Portfolió alakulása
                  </Typography>
                  <Table
                    id="portfolio-development"
                    headCells={detailedIndicators.portfolioDevelopment.cells}
                    rows={detailedIndicators.portfolioDevelopment.data}
                    hideOthersOnSelect={false}
                    selected={undefined}
                    setSelected={() => {}}
                    hidePaper
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Értékesítői aktivitás
                  </Typography>
                  <Table
                    id="agents-activity"
                    headCells={detailedIndicators.agentsActivity.cells}
                    rows={detailedIndicators.agentsActivity.data}
                    hideOthersOnSelect={false}
                    selected={undefined}
                    setSelected={() => {}}
                    hidePaper
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};
