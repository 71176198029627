import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Status, getStatus } from './Status';
import { Input } from '@mui/material';
import React from 'react';
import { ReturnStatus } from '../../../models/productReturns/ReturnStatus';

interface Props {
  selected: ReturnStatus[];
  setSelected: (values: ReturnStatus[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const StatusFilter: React.FC<Props> = ({ selected, setSelected }: Props) => {
  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    setSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',').map((x: string) => value as unknown as ReturnStatus) : value
    );
  };

  return (
    <div>
      <FormControl sx={{ my: 3 }} fullWidth>
        <InputLabel id="status-label">Státus szűrő</InputLabel>
        <Select
          labelId="status-label"
          id="return-status-filter"
          multiple
          variant="standard"
          value={selected}
          onChange={handleChange}
          input={<Input id="select-multiple-status" />}
          renderValue={(values) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {values.map((value: ReturnStatus) => (
                <React.Fragment key={value}>
                  <Status data={value} />
                </React.Fragment>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {Object.keys(ReturnStatus)
            .filter((key: any) => !isNaN(Number(ReturnStatus[key])))
            .map((key) => {
              const value = (ReturnStatus as any)[key];
              const status = getStatus(value);
              return (
                <MenuItem key={value} value={value}>
                  {status!.label}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};
