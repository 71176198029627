import { ReactElement } from 'react';
import { Button, Theme } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import DoneIcon from '@mui/icons-material/Done';
import { ProductReturn } from '../../../models/productReturns/ProductReturn';
import { ReturnStatus } from '../../../models/productReturns/ReturnStatus';

interface Props {
  data: ProductReturn;
  onClick: (productReturn: ProductReturn) => void;
}

export const StatusButton: React.FC<Props> = ({ data, onClick }: Props) => {
  let label: string | undefined;
  let icon: ReactElement | undefined;

  switch (data.status) {
    case ReturnStatus.new: {
      label = 'Feldolgozás';
      icon = (
        <LoopIcon
          sx={(theme: Theme) => ({ backgroundColor: 'transparent !important', fill: theme.palette.primary.main })}
        />
      );

      break;
    }
    case ReturnStatus.inProgress: {
      label = 'Lezárás';
      icon = <DoneIcon color="info" sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />;

      break;
    }
  }

  return label ? (
    <>
      <Button startIcon={icon} color="primary" size="small" onClick={() => onClick(data)}>
        {label}
      </Button>
    </>
  ) : null;
};
