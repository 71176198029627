import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { useEffect, useMemo, useState } from 'react';
import { Product } from '../../../models/common/Product';
import { Access } from '../../../access/Access';
import { LoadProductReturnsRequest } from '../../../models/productReturns/LoadProductReturnsRequest';
import { LoadProductReturnsResponse } from '../../../models/productReturns/LoadProductReturnsResponse';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../slices/applicationSlice';
import { useField } from 'formik';

interface Props {
  name: string;
}

export const SelectProduct: React.FC<Props> = ({ name }: Props) => {
  const fieldProps = useField(name);
  const [field, meta, helpers] = fieldProps;
  const { value } = meta;
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly Product[]>([]);
  const dispatch = useDispatch();

  const fieldError = meta.error;
  const showError = meta.touched && !!fieldError;

  const access = new Access();
  const fetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly Product[]) => void) => {
        const criteria: LoadProductReturnsRequest = {
          loadProducts: {
            searchTerm: request.input,
          },
        };
        access
          .loadProductReturns(criteria)
          .then((value: LoadProductReturnsResponse | undefined) => {
            if (value?.error == null && value?.products != null) {
              callback(value.products);
            } else {
              dispatch(addMessage(value?.error ?? 'Hiba történt'));
            }
          })
          .catch((reason: any) => {
            dispatch(addMessage(reason.message ?? 'Hiba történt'));
          });
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly Product[]) => {
      if (active) {
        let newOptions: readonly Product[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      {...field}
      id="product-selector"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      size="small"
      fullWidth
      noOptionsText="Adja meg a termék nevét vagy kódját"
      onChange={(event: any, newValue: Product | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        helpers.setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Termék"
          fullWidth
          error={showError}
          helperText={showError ? fieldError : undefined}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li key={option.id} {...props}>
            <Typography variant="body2" color="text.secondary">
              {option.name}
            </Typography>
          </li>
        );
      }}
    />
  );
};
