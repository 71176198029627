import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  SwipeableDrawer,
  Typography,
} from '@mui/material';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ProductReturn } from '../../../models/productReturns/ProductReturn';
import { ProductReturnValues } from './ProductReturns';
import { ReturnStatus } from '../../../models/productReturns/ReturnStatus';
import { FormTextField } from '../../common/input/FormTextField';
import { useFormat } from '../../../utility/useFormat';
import { FormSelectField } from '../../common/input/FormSelectField';
import { useEffect, useMemo } from 'react';
import { PartnerSummary } from '../../../models/partners/PartnerSummary';
import { ReturnReason } from '../../../models/productReturns/ReturnReason';
import React from 'react';
import { getReason, Reason } from './Reason';
import { FormDatePicker } from '../../common/input/FormDatePicker';
import { FileUpload } from '../../common/fileUpload/FileUpload';
import { AddFile } from '../../common/fileUpload/FileConfiguration';
import { AgentSummary } from '../../../models/common/AgentSummary';
import { useDispatch, useSelector } from 'react-redux';
import { isAdminRole, isStorekeeperRole } from '../../../slices/userSlice';
import { SelectProduct } from './SelectProduct';
import { LoadProductReturnsRequest } from '../../../models/productReturns/LoadProductReturnsRequest';
import { Access } from '../../../access/Access';
import { LoadProductReturnsResponse } from '../../../models/productReturns/LoadProductReturnsResponse';
import { setSelectedReturn } from '../../../slices/productReturnsSlice';
import { addMessage } from '../../../slices/applicationSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
interface Props {
  data: ProductReturn | undefined;
  isOpen: boolean;
  partners: PartnerSummary[] | undefined;
  agents: AgentSummary[] | undefined;
  onClose: () => void;
  onSave: (values: ProductReturnValues) => void;
}

const currentYear = new Date().getFullYear();

export const EditReturn: React.FC<Props> = ({ data, isOpen, onClose, onSave, partners, agents }: Props) => {
  const { formatDate } = useFormat();
  const dispatch = useDispatch();
  const isAdmin = useSelector(isAdminRole);
  const isStorekeeper = useSelector(isStorekeeperRole);
  const selectAgent = isAdmin || isStorekeeper;

  const initialValues: ProductReturnValues = {
    id: data?.id ?? 0,
    serialNumber: data?.serialNumber ?? '',
    date: data?.date ? formatDate(data.date) : '',
    status: data?.status == null || (data.status as any) === 0 ? ReturnStatus.new : data.status,
    partnerId: data?.partnerId,
    agentId: data?.agentId,
    description: data?.description ?? '',
    reason: data?.reason ?? ReturnReason.warranty,
    deliveryDate: data?.deliveryDate,
    photos: [],
    product: data?.product,
    productCount: data?.product?.count,
  };

  const getTitle = (): string => {
    switch (data?.status) {
      default:
      case undefined:
        return 'Új visszárú hozzáadása';
    }
  };

  const handleSubmit = (value: ProductReturnValues): void => onSave(value);
  const validationSchema = Yup.object({
    partnerId: Yup.number().min(1, 'Kérem adja meg a partnert').required('Kérem adja meg a partnert'),
    description: Yup.string().required('Kérem adja meg a leírást'),
    deliveryDate: Yup.string().when('reason', (values, schema) => {
      const [reason] = values;
      if (reason === ReturnReason.damagedOnDelivery || reason === ReturnReason.misorder) {
        return schema
          .test('validDate', 'Kérem adja meg a szállítás dátumát', (value) => {
            if (typeof value === 'string') {
              const date = new Date(value);
              const year = date.getFullYear();
              if (year < currentYear - 2) return false;
            }
            return value !== 'Invalid date' && value != null && value !== 'null';
          })
          .required('Kérem adja meg a szállítás dátumát');
      }
      return schema.notRequired();
    }),
    product: Yup.mixed().required('Kérem válassza ki a terméket'),
    productCount: Yup.number().min(1, 'Kérem adja meg a darabszámot').required('Kérem adja meg a darabszámot'),
  });

  const agentOptions = useMemo(
    () => (agents == null ? [] : agents.map((agent: AgentSummary) => ({ value: agent.id, label: agent.name }))),
    [agents]
  );

  const initReturn = (values: ProductReturnValues): void => {
    const access = new Access();

    if (values.partnerId == null) {
      return;
    }

    const request: LoadProductReturnsRequest = {
      initiateReturn: { partnerId: values.partnerId, agentId: values.agentId },
    };
    access
      .loadProductReturns(request)
      .then((value: LoadProductReturnsResponse | undefined) => {
        if (value?.error == null && value?.newReturn != null) {
          dispatch(setSelectedReturn(value.newReturn));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      });
  };

  useEffect(() => {
    if (data?.id === -1 && data.partnerId > 0) {
      initReturn(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SwipeableDrawer anchor="right" open={isOpen} onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 400 }, p: 3 }}>
        <Typography variant="h5">{getTitle()}</Typography>
        <Box sx={{ pt: 4 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, errors, values, touched, setFieldValue }) => {
              const partnerOptions =
                partners == null
                  ? []
                  : (!selectAgent ? partners : partners.filter((partner) => partner.agentId === values.agentId)).map(
                      (partner: PartnerSummary) => ({ value: partner.id, label: partner.name })
                    );
              const handleReasonChange = (event: SelectChangeEvent<ReturnReason>) => {
                const {
                  target: { value },
                } = event;
                setFieldValue(
                  'reason',
                  // On autofill we get a stringified value.
                  typeof value === 'string'
                    ? value.split(',').map((x: string) => value as unknown as ReturnReason)
                    : value
                );
              };
              // const handlePartnerChange = (event: SelectChangeEvent<PartnerSummary>) => {
              //   const {
              //     target: { value },
              //   } = event;
              //   let partnerId = 0;
              //   let partnerName = '';
              //   if (typeof value !== 'string') {
              //     partnerId = parseInt(value as any);
              //     partnerName = partners?.find((partner) => partner.id === partnerId)?.name ?? '';
              //   }
              //   setFieldValue('partnerId', partnerId);
              //   setFieldValue('partnerName', partnerName);
              // };
              return (
                <>
                  {(isSubmitting || data == null) && (
                    <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                      <LinearProgress />
                    </Box>
                  )}
                  <Form>
                    {selectAgent && agentOptions != null && (
                      <Box sx={{ width: '100%', mb: 3 }}>
                        <FormControl fullWidth>
                          <FormSelectField
                            name="agentId"
                            id="agentId"
                            errors={errors}
                            isClearable
                            touched={touched}
                            options={agentOptions}
                            defaultValues={values.agentId}
                            setFieldValue={setFieldValue}
                            placeholder="Ügynök"
                            onChange={(_) => {
                              setFieldValue('partnerId', 0);
                            }}
                          />
                        </FormControl>
                      </Box>
                    )}
                    <Box sx={{ width: '100%' }}>
                      <FormControl fullWidth>
                        <FormSelectField
                          name="partnerId"
                          id="partnerId"
                          errors={errors}
                          isClearable
                          touched={touched}
                          options={partnerOptions}
                          defaultValues={values.partnerId}
                          setFieldValue={setFieldValue}
                          placeholder="Partner"
                          onChange={(value) => initReturn({ ...values, partnerId: value })}
                        />
                      </FormControl>
                    </Box>

                    {data != null && data?.id !== -1 && (
                      <>
                        <Box py={2}>
                          <FormTextField
                            size="small"
                            label="Sorozatszám"
                            name="serialNumber"
                            id="serialNumber"
                            fullWidth
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="standard"
                          />
                        </Box>

                        <Box pb={2}>
                          <FormTextField
                            size="small"
                            label="Dátum"
                            name="date"
                            id="date"
                            fullWidth
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="standard"
                          />
                        </Box>

                        {/* {partners && (
                      <FormControl sx={{ mt: 2, pb: 2 }} fullWidth>
                        <InputLabel id="partner-label">Partner</InputLabel>
                        <Select
                          labelId="partner-label"
                          id="partnerId"
                          name="partnerId"
                          variant="standard"
                          value={{ id: values.partnerId ?? 0, name: values.partnerName, agentId: 0 }}
                          onChange={handlePartnerChange}
                          input={<Input id="select-partner" />}
                          renderValue={(value) => value.name}
                          MenuProps={MenuProps}
                        >
                          {partners.map((partner) => (
                            <MenuItem key={partner.id} value={partner.id}>
                              {partner.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )} */}
                        <Box mt={2}>
                          <SelectProduct name="product" />
                        </Box>
                        {values.product != null && (
                          <Box py={2}>
                            <FormTextField
                              size="small"
                              label="Darabszám"
                              name="productCount"
                              id="productCount"
                              fullWidth
                              type="number"
                              variant="standard"
                            />
                          </Box>
                        )}
                        <FormControl sx={{ mt: 4, pb: 2 }} fullWidth>
                          <InputLabel id="reason-label" sx={{ left: -14 }}>
                            Visszaküldés oka
                          </InputLabel>
                          <Select
                            labelId="reason-label"
                            id="reason"
                            name="reason"
                            variant="standard"
                            value={values.reason}
                            onChange={handleReasonChange}
                            input={<Input id="select-reason" />}
                            renderValue={(value) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {/* {values.map((value: ReturnReason) => ( */}
                                <React.Fragment key={value}>
                                  <Reason data={value} />
                                </React.Fragment>
                                {/* ))} */}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {Object.keys(ReturnReason)
                              .filter((key: any) => !isNaN(Number(ReturnReason[key])))
                              .map((key) => {
                                const value = (ReturnReason as any)[key];
                                const status = getReason(value);
                                return (
                                  <MenuItem key={value} value={value}>
                                    {status!.label}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                        {(values.reason === ReturnReason.damagedOnDelivery ||
                          values.reason === ReturnReason.misorder) && (
                          <FormDatePicker
                            label="Szállítás dátuma"
                            name="deliveryDate"
                            closeOnSelect
                            disableFuture
                            views={['year', 'month', 'day']}
                            slotProps={{
                              textField: {
                                variant: 'standard',
                                fullWidth: true,
                                id: 'date',
                                size: 'small',
                                helperText: touched['deliveryDate'] ? errors['deliveryDate'] : undefined,
                                error: touched['deliveryDate'] && errors['deliveryDate'] != null,
                              },
                            }}
                          />
                        )}

                        <Box pb={2}>
                          <FormTextField
                            size="small"
                            label="Hiba leírás"
                            name="description"
                            id="description"
                            fullWidth
                            multiline
                          />
                        </Box>

                        <Box my={2}>
                          <FileUpload
                            disabled={isSubmitting}
                            multiple
                            acceptedFiles=""
                            onDropFiles={(files: AddFile[]) => {
                              if (files.length) {
                                setFieldValue(
                                  'photos',
                                  files.map((file) => file.content)
                                );
                              } else {
                                setFieldValue('photos', undefined);
                              }
                            }}
                          />
                        </Box>
                      </>
                    )}
                    <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button onClick={onClose} disabled={isSubmitting} sx={{ mr: 2 }}>
                        Mégse
                      </Button>
                      <Button variant="contained" type="submit" disabled={isSubmitting || data?.id === -1}>
                        Mentés
                      </Button>
                    </Box>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
