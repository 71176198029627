import { ReactElement } from 'react';
import { Chip } from '@mui/material';
import { ReturnReason } from '../../../models/productReturns/ReturnReason';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import CancelIcon from '@mui/icons-material/Cancel';
import SubjectIcon from '@mui/icons-material/Subject';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

interface Props {
  data: ReturnReason | undefined;
  onDelete?: (id: ReturnReason) => void;
}

export const getReason = (
  status: ReturnReason | undefined
): { label: string; color: any; icon: ReactElement } | undefined => {
  if (status == null) return undefined;
  switch (status) {
    case ReturnReason.warranty:
      return {
        label: 'Jótállás',
        color: 'default',
        icon: <VerifiedUserIcon sx={{ backgroundColor: 'transparent !important' }} />,
      };
    case ReturnReason.damagedOnDelivery:
      return {
        label: 'Szállítási sérült',
        color: 'default',
        icon: <LocalShippingIcon sx={{ backgroundColor: 'transparent !important' }} />,
      };
    case ReturnReason.misorder:
      return {
        label: 'Téves rendelés',
        color: 'default',
        icon: <CancelIcon sx={{ backgroundColor: 'transparent !important' }} />,
      };
    case ReturnReason.broken:
      return { label: 'Törés', color: 'default', icon: <BrokenImageIcon /> };
    case ReturnReason.other:
      return {
        label: 'Egyéb',
        color: 'default',
        icon: <SubjectIcon sx={{ backgroundColor: 'transparent !important' }} />,
      };
  }
};

export const Reason: React.FC<Props> = ({ data, onDelete }: Props) => {
  const status = getReason(data);
  return data && status ? (
    <Chip
      size="small"
      avatar={status.icon}
      sx={{ my: 0.3, minWidth: 105 }}
      color={status.color}
      label={status.label}
      onDelete={
        onDelete != null
          ? () => {
              onDelete(data);
            }
          : undefined
      }
    />
  ) : null;
};
